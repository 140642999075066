// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,600,600i,700,700i%7cPermanent+Marker');

// Variables
@import 'variables';


//Modified based on theme order
@import '~bootstrap/scss/functions';
@import 'custom/functions';
@import '~bootstrap/scss/variables';
@import 'custom/variables-bootstrap';
@import 'custom/variables-theme';
@import 'user-variables';
@import '~bootstrap/scss/bootstrap';
@import 'custom/components';
@import "../../licensedVendors/formvalidation/dist/css/formValidation";
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker';
@import '~vue-form-wizard/dist/vue-form-wizard.min';
@import '~select2/dist/css/select2';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4';
@import '~vue-directive-tooltip/dist/vueDirectiveTooltip.css';
@import 'user';


// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// Insight Theme
//@import "theme";
