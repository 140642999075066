//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.custom-address-label {
    position: relative;
    margin-bottom: 0; }
.fv-plugins-bootstrap .form-group .fv-plugins-icon {
    right: -30px;
}

.message-counter {
    background-color: slategrey;
    color: white;
}

.card-inner {
    border: 2px solid rgba(0, 0, 0, 0.125);
}

fieldset.field-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
}

legend.field-border {
    font-size: 1.0em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

.menuItem[data-toggle].collapsed:after {
    content: " ▾";
}
.menuItem[data-toggle]:not(.collapsed):after {
    content: " ▴";
}

li a span.memberMenu:hover {
    color: #007bff;
}

.menuItem:hover {
    color:  #007bff;
}

.chevron-header .fas {
    transition: .3s transform ease-in-out;
}

.chevron-header .collapsed .fas {
    transform: rotate(90deg);
}

.x-icon {
    height: 1em;
    width: 1em;
    top: .125em;
    position: relative;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
    color: white;
    background: #152a49;
}

.vue-tooltip.tooltip-custom {
    background-color: #152a49;
}

.vue-tooltip.tooltip-custom .tooltip-arrow {
    border-color: #152a49;
}

.nav-pills .nav-link.active {
    color: white;
    background: #0099FF;
}


